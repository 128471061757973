<template>

  <div class="pop-up" v-if="isShow">
    <div class="pop-container">
      <h3>Password</h3>
      <input class="password" type="password" placeholder="Password" v-model="password" @keydown.enter="savePassword" ref="passwordInput">
      <p class="tips">{{tips}}</p>
      <div class="confirm-btn"  @click="savePassword">
        Confirm
      </div>
    </div>
  </div>

  <!-- 加载中 -->
  <div class="main" v-if="isLoadingShow">
    <Loading/>
  </div>


<div class="sm-container">
<!--<div class="sm-nav">-->
<!--  Home  ›  Halloween Toys  ›  product details-->
<!--</div>-->
  <div class="details">
    <h1>Product Details</h1>
    <div class="pro-middle">
      <div class="pro-left">
       <img :src="image">
      </div>
      <div class="pro-right">
       <h2>{{ title }}</h2>
        <div class="pro-r-1">
          <span>No: {{ no }}</span>
          <a :href="pdf" target="_blank">
            <div>
              <img src="@/assets/img/download.png"> download product Instructions
            </div>
          </a>
        </div>
        <div class="pro-r-2"  v-html="processedDescription">
        </div>
      </div>
    </div>

    <div class="component"  v-if="components.length > 0">
      <h2>Component</h2>
      <div class="component-item" v-for="item in components" :key="item.id">
         <div class="item-left">
           <div class="stock" v-if="item.stock===0">Unavailable</div>
           <img :src="item.image">
         </div>
        <div class="item-right">
          <div class="item-right-content">
          <div class="item-item">
           <div>
             <span>Component name:</span>
             <span>{{ item.name }}</span>
           </div>
          <div>
            <span>Component Code:</span>
            <span>{{ item.code }}</span>
          </div>
          <div>
            <span>Part NO.：</span>
            <span>{{ item.part_no }}</span>
          </div>
          <div>
            <span>Description:</span>
            <span>{{ item.description}}</span>
          </div>
         </div>
          <div class="component-bottom">
            <div class="component-count">
              <span @click="decreaseComponentCount(item.id)" :class="{ 'disabled': item.disabled || item.stock === 0}">-</span>
              <input class="c-count" type="text" :value="item.count" readonly>
              <span @click="increaseComponentCount(item.id)" :class="{ 'disabled': item.disabled || item.count === item.stock }">+</span>
            </div>
            <div class="add-cart" @click="addToCart(item.id)" :class="{ 'disabled': item.stock === 0 }">
              Add to cart
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>
</template>

<script>
import Loading from "@/components/loading";
import {getProductDetails} from "@/network/main";
export default {
  name: "productDetails",
  components: {
    Loading
  },
  data() {
    return{
      id:null,
      isShow:true,
      isLoadingShow:true,
      title:'',
      image:'',
      pdf:'',
      content:"",
      no:'',
      components:[],
      selectedId: null,
      tips:'',
      isPopupVisible: false,
      popupText: '',
      password:'',
    }
  },
  created() {
    const password = this.getCookie("password");
    if (password !== null && password !== undefined) {
      this.isShow = false;
      this.password = password; // 更新密码属性
      this.id = this.$route.params.id; // 获取 id 参数
      this.getProductDetails();
    } else {
      this.isShow = true;
      this.isLoadingShow = false;
    }
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(newId) {
        this.id = newId;
        // this.getProductDetails();
      },
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    // 获取指定名称的cookie值
    getCookie(name) {
      const cookies = document.cookie.split("; ");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");
        if (cookie[0] === name) {
          return cookie[1];
        }
      }
      return null;
    },
    // 设置cookie
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    },
    //键盘enter
    handleKeyDown(event) {
      if (event.keyCode === 13 && document.activeElement === this.$refs.passwordInput) {
        this.savePassword();
      }
    },
    //savePassword
    savePassword() {
      if (!this.password) {
        this.tips = "Password is required";
        return;
      }
      this.setCookie("password", this.password, 1);
      this.id = this.$route.params.id; // 获取 id 参数
      this.getProductDetails();
    },

    //产品详情
    getProductDetails() {
      getProductDetails(this.id).then(res =>{
        if (res.data.code === 200) {
          this.isShow = false;
          this.isLoadingShow = false;
          this.title = res.data.data.title;
          this.image = res.data.data.image;
          this.pdf = res.data.data.pdf;
          this.content = res.data.data.content;
          this.no = res.data.data.no;
          this.components = res.data.data.components.map(component => ({
            ...component,
            count: 0
          }));
        }else if (res.data.code === 401) {
          this.tips = res.data.message;
        }
      })
    },
    //-+
    decreaseComponentCount(id) {
      const component = this.components.find(item => item.id === id);
      if (component && component.count > 0 && component.stock > 0) {
        component.count--;
        if (component.count === 0) {
          component.disabled = true;
        }
      }
    },
    increaseComponentCount(id) {
      const component = this.components.find(item => item.id === id);
      if (component && component.count < component.stock) {
        component.count++;
        if (component.count === 1) {
          component.disabled = false;
        }
      }
    },
    //addToCart
    addToCart(id) {
      const component = this.components.find(item => item.id === id);
      if (component && component.count > 0 && component.stock > 0) {
        let selectedIds = JSON.parse(localStorage.getItem('selectedIds')) || {};
        selectedIds[id] = component.count;
        localStorage.setItem('selectedIds', JSON.stringify(selectedIds));
        // 计算总数量
        let selectedIdsLength = Object.keys(selectedIds).length;
        localStorage.setItem('selectedIdsLength', selectedIdsLength);

        console.log(selectedIds);
        console.log(selectedIdsLength);

        // 触发自定义事件
        const event = new CustomEvent('selectedIdsLengthUpdated', { detail: selectedIdsLength });
        window.dispatchEvent(event);

        //
        this.isPopupVisible = true;
        this.popupText = "Added to cart";
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 1500);
      }
    },
  },
  computed: {
    processedDescription() {
      if (this.content) {
        return this.content.replace(/\r?\n/g, "<br>");
      }
      return ""; // 如果content为空，则返回一个空字符串
    },
  }
}
</script>

<style scoped>
.details {
  min-height: 800px;
}
.details h1{
  padding-top: 40px;
}
.sm-nav{
  padding: 20px 0;
}
.pro-middle{
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}
.pro-left{
  width: calc(100% - 580px);
  border: 1px solid #B2B2B2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pro-left img{
  max-width: 450px;
  max-height: 620px;
}
.pro-right{
  width: 540px;
}
.pro-r-1{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #D9D9D9;
}
.pro-r-1 span{
  background: #b7b7b7;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
}
.pro-r-1 div{
  background: #EBAC62;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}
.pro-r-1 div img{
  height: 16px;
  margin-right: 10px;
}
.pro-r-2{
  padding-top: 20px;
}
.pro-r-2  p{
  padding: 5px 0;
}
.pro-r-2 p img, .pro-r-2 img {
  padding: 10px 0;
  max-width: 100%!important;
  width: inherit!important;
  height: inherit!important;
  display: block;
  margin: auto;
}
.component h2{
 padding-bottom: 30px;
}
.component-item{
  display: flex;
  height: 400px;
  border: 1px solid #C0C0C0;
  border-radius: 14px;
  margin-bottom: 30px;
  position: relative;
}
.item-left{
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-left img{
  max-height: 400px;
  padding: 15px;
}
.stock{
  background: #FF3A3A;
  color: #fff;
  padding: 5px;
  position: absolute;
  top: 20px;
  left: 20px;
}
.item-right{
  width: calc(100% - 450px);
  padding: 25px 40px;
  border-left: 1px solid #C0C0C0;
}
.item-item>div{
  display: flex;
  padding: 7px 0;
}
.item-item div span:first-child{
  width: 160px;
  color: #676767;
}
.item-item div span:last-child{
  width: calc(100% - 160px);
}
.component-bottom{
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #C0C0C0;
}
.component-count{
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #EBEBEB;
  border-radius: 4px;
}
.c-count{
  width: 50px;
  height: 42px;
  border: none;
  background: transparent;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
.component-count span{
  font-weight: bold;
  font-size: 20px;
  padding: 8px 15px;
  cursor: pointer;
}
.add-cart{
  width: 150px;
  height: 42px;
  line-height: 42px;
  background: var(--background);
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  margin-left: 30px;
  border-radius: 4px;
  text-align: center;
}
.no-click{
  background: #EBEBEB;
  cursor: not-allowed;
}
.disabled{
  opacity: 0.5;
  cursor: not-allowed!important;
  background: #aaaaaa;
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0,.8);
  padding: 15px;
  border-radius: 4px;
  color: #fff;
}
@media only screen and (min-width:0px) and (max-width:767px){
  .details h1{
    display: none;
  }
  .pro-middle{
     flex-wrap: wrap;
    padding: 10px 0;
  }
  .component-item{
    height: auto;
  }
  .pro-left{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .pro-left img{
    max-height: 350px;
  }
  .pro-right{
    width: 100%;
    padding-top: 20px;
  }
  .pro-r-1{
    flex-wrap: wrap;
  }
  .pro-r-1 div{
    margin-top: 20px;
  }
  .component-item{
    flex-wrap: wrap;
  }
  .item-left{
    width: 100%;
    padding: 10px;
  }
  .item-right{
    width: 100%;
    padding: 20px;
    border-left: none;
    border-top: 1px solid #C0C0C0;
  }

}



</style>